.App {
  text-align: center;
}

.App-logo {
  height: 50vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header p {
  font-size: calc(4px + 2vmin);
}

.App-link {
  color: #61dafb;
}

.App-twitter-logo {
  height: 5vh;
  margin: 2vh;
}
